import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import BlogPostCard from './blogPostCard'
import styled from 'styled-components'

const FlexContent = styled.div`
 	display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items:flex-start;
    margin-top:1.6rem;

`
const BlogPostList = ({data, path = ""}) => {
  
/*  const pageAnimation = useSpring({
    config: config.slow,
    from: {  transform: 'translate3d(0, 50px, 0)'  },
    to: {  transform: 'translate3d(0, 0, 0)'  },
  })*/
  
 /*console.log(data)*/
  return (
  
  
	<FlexContent /*style={pageAnimation}*/ >
		
		  { data.map((blogPost,i) => {         
                return (
                  <BlogPostCard  
                    key={i} 
                    slug={path+blogPost.slug} 
                    title={blogPost.titre} 
                    date={blogPost.date} 
                    image={ <GatsbyImage image={blogPost.image.gatsbyImageData} alt={blogPost.titre} />}
                  />
                 )     
              }) 
            }
		
	</FlexContent>

  )

}

export default BlogPostList
