import React from 'react'
import  {GatsbyImage}  from 'gatsby-plugin-image'
import  {Link}  from 'gatsby'
import { MdDateRange } from 'react-icons/md'
import { colors, mq, space } from '../../consts/style';
import styled from 'styled-components'
import {
  FocusText
} from "../Elements"



const Content = styled.div`
  z-index: 1;
  padding: 1.4rem 3.2rem 3.2rem;
  margin-left: -12.8rem;
  margin-top: 280px;
  width: auto;
  max-width: 68.8rem;
  background-color: ${colors.backgroundGrayLight};
  border-radius: 0.8rem;
  transition: all .3s ease-out 0s;
  ${mq.tablet`
      margin-left:0;
      margin-right:0;
      flex-direction:column;
      border-radius: 0 0 0.8rem 0.8rem ;
      `
    }
`

const Card = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction:row;
  justify-content: center;
  margin-right:1rem;
  margin-bottom:${space.quarter};
  margin-top:${space.double};
  border-radius: 0 0 ${space.half}  ${space.half};
  transition:all .3s;
  ${mq.mobile` 
    margin-left:0;
    margin-right:0;
    flex-direction:column;
  `}
  margin-bottom: 12.8rem;

  &:hover {

    ${Content} {
      background-color: ${colors.backgroundGreenLight};
      transition: all .3s ease-out 0s;
      box-shadow: 0 4px 4px #c2d3dd;
      transform: translateY(-5px);
    }
  
   

  }
 
 
`


const Image = styled.div`
    position: absolute!important;
    top: 0;
    left: 0;
    width: 100%;
    height: 400px;
    z-index: auto;
    overflow: hidden;
    border-radius: 0.8rem;
`
const StyledLink = styled(Link)`
  position: absolute;
  left: 0;
  top: 0;
  height:100%;
  z-index: 10;
  width:100%; 
`
const StyledDate = styled.div`
  display:block;
  color: ${colors.text2};
  font-size:1.4rem;
  .icon-blog-date {
    font-size: 2.4rem;
    margin-right: 0.8rem;
    vertical-align: sub;
  }
`



const BlogPostCardFeatured = ({data}) => {


  return (
	<Card>
		<Image>
      <GatsbyImage image={data.image.gatsbyImageData} alt={data.titre}/>
    </Image>
    <Content>
      <StyledDate><MdDateRange className="icon-blog-date"/>{data.date}</StyledDate>
      <h3>{data.titre}</h3>
      <FocusText  dangerouslySetInnerHTML={{ __html: data.teaser }}/>
      <StyledLink to = {data.slug} aria-label= {data.titre} ></StyledLink>
    </Content>
    
	</Card>
  )

}

export default BlogPostCardFeatured
