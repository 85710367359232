import React, { Fragment } from "react"
import { graphql } from 'gatsby'

/*import styled from 'styled-components'*/

import Seo from "../components/seo"
import Anim from '../components/animations/Anim'
import BlogPostList from '../components/blog/blogPostList'
import BlogPostCardFeatured from '../components/blog/blogPostCardFeatured'
import {
  Banner,
  PageWrapper,
  PageInner,
  Title,
  PageTitle,
  Spacer,
  Text2Col,
  ArrowLeftLink,
  ArrowRightLink,
  ArrowLeftIcon,
  ArrowRightIcon,
  Text,
} from "../components/Elements"


const BlogPage = ({data}) => {
  
  return (
    <Fragment>
      <Seo meta={data.page.seoMetaTags}/>
      <PageWrapper>
        <PageInner>
          <Anim>
            <BlogPostCardFeatured data={data.latestBlogPost.edges[0].node} />
            <BlogPostList data={data.previousBlogPosts.nodes} />
          </Anim>
        </PageInner>     
      </PageWrapper>
    </Fragment>
)
}

export default BlogPage

 
export const query = graphql`
  query blogPageQuery($locale: String) {

    page:datoCmsBlogPage(locale: {eq: $locale}) {
      titre
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
    }

    latestBlogPost:allDatoCmsBlog (filter: {locale: {eq: $locale}},  limit:1, sort: {fields: date,order: DESC}){
      edges {
        node {
          id
          slug
          titre
          teaser
          
          date(formatString: "ll", locale:"fr")
          meta {
            createdAt(formatString: "ll", locale:"fr")
          }
          image{
            gatsbyImageData(  
              placeholder: BLURRED,
              forceBlurhash: false,
              width:1312 
            )
          }
          seoMetaTags {
            ...GatsbyDatoCmsSeoMetaTags
          }
        }
      }
    } 
    previousBlogPosts: allDatoCmsBlog (filter: {locale: {eq: $locale}}, skip:1, sort: {fields: date,order: DESC}){
      totalCount
        nodes {
          slug
          id
          titre
          date(formatString: "ll", locale:"fr")
          seoMetaTags {
            ...GatsbyDatoCmsSeoMetaTags
          }
          
          
          
          meta {
            createdAt(formatString: "ll", locale:"fr")
          }
          image{
            gatsbyImageData(  
              placeholder: BLURRED,
              forceBlurhash: false,
              width:900 
            )
          }  
        }
      
    }
  } 
`