import React from 'react'
import {GatsbyImage} from 'gatsby-plugin-image'
import  {Link}  from 'gatsby'
import { MdDateRange } from 'react-icons/md'
import styled from 'styled-components'
import { colors, mq, space } from '../../consts/style';


const Content = styled.div`
  padding:${space.default};
`


const StyledLink = styled(Link)`
  position: absolute;
  left: 0;
  top: 0;
  height:100%;
  z-index: 10;
  width:100%; 
`
const StyledDate = styled.span`
  display:block;
  color: ${colors.text2};
  font-size:1.4rem;
  .icon-blog-date {
    font-size: 2.4rem;
    margin-right: 0.8rem;
    vertical-align: sub;
  }
`

const Card = styled.div`
  width: 360px;
  position: relative;
  display: flex;
  flex-direction:column;
  justify-content: center;
  margin-right:1rem;
  margin-bottom:6.4rem;
  background-color: ${colors.backgroundGreenLight};
  border-radius: 0 0 ${space.half} ${space.half};
  transition:all .3s;
  @include for-phone-only {
  margin-left:0;
    margin-right:0;
  }

  &:hover {
    background-color: ${colors.yellow};
    transition: all 0.3s ease-out 0s;
    box-shadow: ${colors.background} 0px 4px 4px;
    transform: translateY(-5px);
  }
`

const BlogPostCard = ({slug, title, date, image}) => {

  return (
	<Card>
		{image}
    <Content>
      <StyledDate><MdDateRange className="icon-blog-date"/>{date}</StyledDate>
      <h3>{title}</h3>
      </Content>
    <StyledLink   to = {slug} aria-label= {title} ></StyledLink>
	</Card>
  )

}

export default BlogPostCard
